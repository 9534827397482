import React from 'react';
import 'semantic-ui-css/semantic.min.css';
import '../layout.css';
import Layout from '../components/layout';
import { Grid, Image } from 'semantic-ui-react'
import { css } from '@emotion/css'
import { mq } from '../styles';
import homeHeroImg from '../../assets/images/home-hero.jpg';


class Home extends React.Component {
    render() {
        return (
            <Layout pageName='Home'>
                <Image src={homeHeroImg} className={heroImage} />
                <Grid container className={heroText}>
                    <Grid.Row>
                        <Grid.Column>
                            <p>Fast Algorithms & Data Structures in Rust</p>
                            <p className={subText}>Open Source, Built for Speed</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Layout>
        );
    }
}

export default Home;

const subText = css(mq({
    color: 'white',
    fontSize: ['14px', '14px', '18px'],
    fontWeight: 'normal',
}));

const heroText = css(mq({
    zIndex: [100],
    position: ['absolute'],
    color: 'white',
    fontSize: ['20px', '20px', '30px'],
    fontWeight: 'bold',
    left: ['20px', '60px', '120px'],
    top: ['100px', '110px', '120px']
}));

const heroImage = css({
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '500px',
});
